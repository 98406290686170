export const blockchainKeys = {
    tokenContract: (chain, address) => ['contract', chain, address],
    nftCollection: (chain, address, tokenId) => [
        'nftContract',
        chain,
        address,
        tokenId ?? '',
    ],
    nftCollectionBatch: (chain, addresses) => ['nftContract', chain, ...addresses],
};
