import { useQuery } from '@tanstack/react-query';
import { blockchainKeys } from '../blockchain.keys';
import { getNFTCollectionMetadata } from '../blockchain.service';
export const useNFTCollectionMetadata = ({ contract, network, tokenId, }, options) => {
    return useQuery({
        ...options,
        queryKey: blockchainKeys.nftCollection(network, contract, tokenId),
        queryFn: () => getNFTCollectionMetadata({ contract, network, tokenId }),
        retry: false,
    });
};
